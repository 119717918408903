import Keycloak from "keycloak-js";
import { ref } from 'vue';

const access_token = ref(false);

export function useKeycloak() {

    async function init() {

        let interval = false;
        console.log('using keycloak')
        const config = {
            url: process.env.VUE_APP_KEYCLOACK_URL || "https://35.157.183.54:8443/auth",
            realm: process.env.VUE_APP_KEYCLOACK_REALM || "demo",
            clientId: process.env.VUE_APP_KEYCLOACK_CLIENT_ID || "invoicer",
        };

        const initOptions = {
            onLoad: 'login-required'
        };

        const keycloak = new Keycloak(config);

        await keycloak.init(initOptions).then((auth) => {
            if (!auth) {
                console.log('needs login')
                keycloak.login()
                access_token.value = keycloak.token;
            } else {
                console.info("Already authenticated");
                access_token.value = keycloak.token;
                refreshInterval(keycloak, interval)
            }
            }).catch(() => {
                console.error("Authenticated Failed");
            }
        );

        return true;
    }


    function refreshInterval(keycloak, interval) {
        updateToken(keycloak)
        if (interval === false) {
            interval = setInterval(() => {
                updateToken(keycloak);
            }, 60000)
        }
    }

    function updateToken(keycloak) {
        keycloak.updateToken(70).then((refreshed) => {
            if (refreshed) {
                access_token.value = keycloak.token;
            } else {
                console.warn('Token not refreshed, valid for '
                + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
            }).catch(() => {
            console.error('Failed to refresh token');
        });
    }

    return { init, access_token }

}