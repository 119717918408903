import { createRouter, createWebHistory } from "vue-router";

import Elite2024 from '../views/Elite2024.vue'

const routes = [

    {
      path: '/daily/',
      name: 'daily',
      component: Elite2024
    },
    {
      path: '/',
      name: 'week',
      component: Elite2024
    }
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
