export function useBattleTools() {

    function isCompliant(compliance_pct) {
        return compliance_pct == undefined || compliance_pct >= 93
    }

    function isDateApplicable(sale_closed_date, start_date_time, end_date_time) {
        const sale_dt = new Date(sale_closed_date)
        const start_dt = new Date(start_date_time)
        const end_dt = new Date( end_date_time)
        return sale_dt >= start_dt && sale_dt <= end_dt
    }

    function getFilterDate() {
        const url_date = new URL(window.location.href).searchParams.get('date');
        if (url_date) {
            return url_date;
        }
        return new Date().toISOString().slice(0, 10);
    }

    const getShortDayName = (date) =>
        date.toLocaleDateString('nl-NL', { weekday: 'short' });

    // function getDateRangeAsDayArray(start_date, end_date) {
    //     const dt_end_date = new Date(end_date);
    //     // const dt_current_date = new Date();
    //     // const min_dt = dt_end_date > dt_current_date ? dt_current_date : dt_end_date

    //     const arr = [];
    //     for(const dt=new Date(start_date); dt<=dt_end_date; dt.setDate(dt.getDate()+1)){
    //         arr.push(new Date(dt))
    //     }
    //     return arr.map((el, index) => { return {"index": index, "date": el.toISOString().slice(0, 10)}});
    // }

    function getDateRangeAsDayArray(start_date, end_date) {
        const dt_end_date = new Date(end_date);
        const dt_current_date = new Date();
        const min_dt = dt_end_date > dt_current_date ? dt_current_date : dt_end_date

        const arr = [];
        for(const dt=new Date(start_date); dt<=dt_end_date; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt))
        }
        return arr.map((el, index) => { return {"index": index, "date": el.toISOString().slice(0, 10), "is_current_or_past": el <= min_dt}});
    }

    return { isCompliant, isDateApplicable, getFilterDate, getDateRangeAsDayArray, getShortDayName }

}