import { ref, computed } from 'vue'
import { useBattleTools } from './battletools.js'
const battle_data = ref({})
const battle_teams = ref([])
const battle_members = ref([])
const battle_modifiers = ref([])
const battle_sales = ref([])
const battle_compliance = ref([])
const battle_accelerators = ref([])
const battle_additional_points = ref([])
const battle_matches = ref([])
const { isCompliant, isDateApplicable, getFilterDate, getDateRangeAsDayArray } = useBattleTools()

// if filter_date is given, sales will be filtered by date
export function useBattleData(options) {

        // Get the DateRange as an array of objects with a date and an empty teams array.
        const dateWithTeams = computed(() => {
            if (options.daily === true) {
                return getDateRangeAsDayArray(battle_data.value.start_date, battle_data.value.end_date).map((el) =>
                    { return { date: el, teams:[] }}
                )
            } else {
                return [{ date: { index: 0, date: battle_data.value.start_date}, teams:[] }]
            }
        })

        /*
            cappedSales is a drop-in replacemnt for battle_sales. It will cap the sales of EESM02 to 3000 per partner and add the difference to the fee_partner.
            To disable it, you can just return battle_sales.value or replace it in the getMemberTotal function for battle_sales.value.
        */
        const cappedSales = computed(() => {

            const caps = battle_sales.value.reduce((acc, cur) => {
                if (cur.campaign_code.startsWith('EESM02')) {
                    const item = acc.find((el) => el.user_id === cur.user_id)
                    if (!item) {
                        acc.push({"fee_eesp02": 3000, "user_id": cur.user_id})
                    }
                }
                return acc
            },[])

            return battle_sales.value.reduce((acc, cur) => {
                if (cur.campaign_code.startsWith('EESM02')) {
                    const item = caps.find((el) => el.user_id === cur.user_id)
                    if (item) {
                        const calculated_fee = item.fee_eesp02 - cur.sum.fee_partner
                        // console.log(calculated_fee, cur.sum.fee_partner, item.fee_eesp02)
                        if (calculated_fee < 0) {
                            item.fee_eesp02 = 0
                            cur.sum.fee_partner = cur.sum.fee_partner + calculated_fee // will actually substract because it its the difference
                        } else {
                            item.fee_eesp02 = calculated_fee
                        }
                    }
                }
                acc.push(cur)
                return acc
            },[])


        })

        // Get the teams in total and each member.
        const allTeams = computed(() => {
            return battle_teams.value.map((el) => {
                const members = battle_members.value.filter((mb) => mb.battle_team === el.id).map((el) => { return {
                    user_id: el.battle_user.user_id,
                    first_name: el.battle_user.first_name,
                    last_name: el.battle_user.last_name,
                    is_captain: el.is_captain,
                    battle_team: el.battle_team,
                }})
                return { id: el.id, team_name: el.name, coins: el.coins, members: members }
            })
        })

        // For each team in each date, add the team members with their sales data.
        const calculatedTeamsPerDay = computed(() => {
            return dateWithTeams.value.map((dt) => {
                const teams = allTeams.value.map(teamMapper(dt))
                return { date: dt.date, teams: teams }
            })
        })

        // Mapper function to add team members to the team and return the result
        const teamMapper = (dt) => (team) => {
            const team_members = team.members.reduce(teamReducer(dt.date.date),{ "total_count": 0, "total_fee": 0, "total_fee_compliant": 0, "accelerated": 0.0, "campaign_modifier": 0.0, "members": [] })
            const sorted_members = team_members.members.sort((a, b) =>
                ((b.is_compliant === a.is_compliant) ? 0 : a.is_compliant ? -1 : 1)
                || (b.total.total_fee_compliant - a.total.total_fee_compliant)
            )
            return {
                id: team.id,
                team_name: team.team_name,
                coins: team.coins,
                members: sorted_members,
                total_count: team_members.total_count,
                total_fee: team_members.total_fee,
                total_fee_compliant: team_members.total_fee_compliant,
                accelerated: team_members.accelerated,
                campaign_modifier: team_members.campaign_modifier
            }
        }

        // Reducer to update team with totals and member data.
        const teamReducer = (dt) => (acc, cur) => {
            const member = memberCalculator(dt, cur)
            acc.total_count += member.total.total_count
            acc.total_fee += member.total.total_fee
            acc.total_fee_compliant += member.total.total_fee_compliant
            acc.accelerated += member.total.accelerated
            acc.campaign_modifier += member.total.campaign_modifier
            acc.members.push(member)
            return acc
        }

        // The mapper function for each team member.
        const memberCalculator = (dt, battle_member) => {
            const compliance = getMemberCompliance(battle_member)
            const total = getMemberTotal(battle_member, dt, compliance.is_compliant)
            return {
                battle_team: battle_member.battle_team,
                first_name: battle_member.first_name,
                last_name: battle_member.last_name,
                is_captain: battle_member.is_captain,
                compliance_pct: compliance.compliance_pct,
                is_compliant: compliance.is_compliant,
                total: total}
        }

        // Add sale data to the members, returned as an object with count, fee, accelerator and campaign modifier.
        const getMemberTotal = (battle_member, dt, is_compliant) => {

            let member_sales;
            if (options.daily === true) {
                // member_sales = battle_sales.value.filter((el) => el.user_id === battle_member.user_id && el.sale_closed_date.slice(0, 10) === dt)
                member_sales = cappedSales.value.filter((el) => el.user_id === battle_member.user_id && el.sale_closed_date.slice(0, 10) === dt)
            } else {
                // member_sales = battle_sales.value.filter((el) => el.user_id === battle_member.user_id)
                member_sales = cappedSales.value.filter((el) => el.user_id === battle_member.user_id)
            }
            return member_sales.reduce(scoreReducer(battle_member, is_compliant), {
                total_count: 0,
                total_fee: 0,
                accelerated: 0.0,
                campaign_modifier: 0.0,
                total_fee_compliant: 0.0
            })
        }

        // This function is used to reduce a team member's sales to a single object with total fee, count etc.
        const scoreReducer = (battle_member, is_compliant) => (total, current_sale) => {
            total.total_fee += current_sale.sum.fee_partner;
            total.total_fee_compliant = is_compliant ? total.total_fee : 0.0
            total.total_count += Number(current_sale.count);
            total.accelerated += getAccelerated(battle_member, current_sale)
            total.campaign_modifier += getCampaignModifier(current_sale)
            return total
        }

        // Fetch a team member's compliance value in percent and whether they are compliant.
        const getMemberCompliance  = (battle_member) => {
            const compliance = battle_compliance.value.find((compliance) => compliance.partner_id == battle_member.user_id)
            const compliance_pct = compliance ? compliance.compliance_pct : undefined
            const is_compliant = isCompliant(compliance_pct)
            return { compliance_pct, is_compliant }
        }

        /*
            Accelerator's can multiply the value of a sale. Different accelerators can be applied to a single sale.
            We get the accelerators of which the date range meets the sale closed date. The team can be empty, which means all teams get
            the accelerator. If the team is not empty, the accelerator is only applied to the team of the sale.
        */
        const getAccelerated = (battle_member, sale) => {
            return battle_accelerators.value.filter((el) =>{
                const applicable = isDateApplicable(sale.sale_closed_date, el.start_date_time, el.end_date_time)
                return (el.battle_team == battle_member.battle_team || el.battle_team == null) && applicable
            }).reduce((acc, cur) => {
                acc += (sale.sum.fee_partner * cur.accelerator) - sale.sum.fee_partner
                return acc
            }, 0.0)
        }

        /*
            Campaign modifiers can add or subtract points from a sale based on the campaign code.
        */
        const getCampaignModifier = (sale) => {
            const campaign_modifier = battle_modifiers.value.find((el1) => el1.campaign_code == sale.campaign_code)
            const campaign_adjustment = campaign_modifier ? campaign_modifier.point_adjustment : 0.0
            return campaign_adjustment * parseInt(sale.count) // since sales could be grouped by campaign.
        }

        /*
            The matches are only used for battles that have matches
        */
        function getMatches() {
            if (options.filter_date == false) {
                return battle_matches.value;
            } else {
                return battle_matches.value.filter((el) => el.match_date == getFilterDate());
            }
        }

        return  { battle_data, battle_teams,battle_members,battle_modifiers,battle_sales,battle_compliance,battle_accelerators,battle_additional_points, battle_matches, getMatches, calculatedTeamsPerDay, cappedSales}

}