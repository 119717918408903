<script setup>
  import { RouterView } from 'vue-router'
  import { useKeycloak } from './composables/keycloak.js'
  const { init, access_token } = useKeycloak()

  const token = new URL(window.location.href).searchParams.get('token');

  if (!token) {
    init()
  }
</script>
<template>
  <RouterView v-if="access_token || token"/>
</template>

<style>

@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

html, body {
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
}

/* overwrite for elite */
body {
    color: #E6E3C4;
    display:block;
    background-color: #1c1d20;
}

#app {
  height: 100%;
}
</style>